
/* a button that displays information about dependant objects */
.btn-dependant-objects-info {
	background-color: #B9E7FF;
	border: 1px solid #428BCA;
}

.btn-dependant-objects-default {
	/* :#dff0d8;border-color:#d6e9c6 */
	color: #adadad;
	background-color: #ebebeb;
	border: 1px solid #adadad;
}


.btn-dependant-objects-success {
	/* :#dff0d8;border-color:#d6e9c6 */
	color: #468847;
	background-color: #dff0d8;
	border: 1px solid #468847;
}

.btn-dependant-objects-warning {
	color: #c09853;
	background-color: #faebcc;
	border: 1px solid #c09853;
}

.btn-dependant-objects-danger {
	color: #ac2925;
	background-color: #f2dede;
	border: 1px solid #ac2925;
}

.btn-parent-object-info {
	background-color: #B500FF;
	border: 1px solid #600087;
	color: white;
}

.alert_success_close_btn {
	color: #333;
	background-color: #e6e6e6;
	border-radius: 4px;
	border: 1px solid #adadad;
	padding: 6px 12px;
}

	.alert_success_close_btn:hover	{
		background-color: #c9c9c9;
	}
