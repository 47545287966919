
/* Kanban Boards */

.kanban-lane {
	padding: 5px;
	text-align: center;

	flex: 1;

	min-width: min-content;
	-moz-min-width: min-content;
	-webkit-min-width: min-content;
}

.kanban-lane-fixed {
	flex: 0 0;
	max-width: 220px;
}

.kanban-lane-container {
	font-family: Lato;
	min-width: 210px;
	border-radius: 5px;
	border: 1pt solid steelblue;
	margin: -1pt;
/*
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.24);
	transition: 0.3s;
*/
}
.kanban-lane-container.dragging {
	border: 1pt dashed #333;
	background-color: #eee;
}
.kanban-lane-container:hover {
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.8);
}

.kanban-lane-heading {
	padding: 0.7em;
	background-color: steelblue;
	color: white;
	cursor: pointer;
}

.kanban-lane-body {
	padding: 2px;
	overflow-x: hidden;
	flex: 1 0 0;
}

.kanban-task-container {
	display: flex;
	flex-wrap: wrap;
	flex: 1;

	min-width: min-content;
	-moz-min-width: min-content;
	-webkit-min-width: min-content;

	min-height: min-content;
	-moz-min-height: min-content;
	-webkit-min-height: min-content;

	align-items: stretch;
}

.kanban-task {
	font-family: Lato;
	margin: 2px 3px 0 0px;
	width: 100%; /* TODO (Johan) : Find a better alternative; */

	/* Add shadows to create the "card" effect */
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.24);
	transition: 0.3s;
	border-radius: 5px; /* 5px rounded corners */
	font-size: 0.9em;
}
.kanban-task:hover {
	box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.8);
}

.kanban-task-heading {
	padding: 0.2em;
	background-color: #063;
	color: white;
	cursor: move;
	border-radius: 5px 5px 0px 0px;
}
.kanban-task-body {
	padding: 5px;
	font-size: 0.9em;
}
.kanban-task-footer {
	padding: 0.2em;
	font-size: 0.8em;
	border-top: 1px solid #666;
}

.kanban-task-footer > .task-status {
	margin-bottom: 0.2em;
}

.kanban-lane-collapsed {
	padding: 5px;
	text-align: center;

	flex: 0 0 0.7em;
	display: flex;

	min-width: min-content;
	-moz-min-width: min-content;
	-webkit-min-width: min-content;
}
.kanban-lane-collapsed > .kanban-lane-heading {
	display: flex;
	width: 2.4em;
	align-items: flex-start;
	white-space: nowrap;
	justify-content: center;
	border-radius: 5px;
}
.vertical-text{
	transform: rotate(270deg) translate(-50%, 0%);
}

select#kanban-board-selection{
	font-family: Lato;
	font-weight: normal;
	position: absolute;
	left: 25px;
	width: 350px;
}

