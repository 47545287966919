:root {
	--fa-primary-color: rgb(80, 80, 80);
	--fa-secondary-color: rgb(120, 120, 120);
	--fa-primary-opacity: 1;
	--fa-secondary-opacity: 1;
}

body {
	font-family: 'Andika';
	height: 100%;
}

html {
	height: 100%;
}

.hide_a a {
	display:none;
}

.dropdown-menu > li > a{
	cursor: pointer;
}

#divtaskscontainer {
	width:90% !important;
	padding-right : 5% !important
}

#status_container {
	width: 10% !important;
}

.green_button {
	color: #5cb85c;
}

.red_button {
	color: #d9534f;
}

.span_club {
	top: 40%;
}

.search_task {
	height: 20px;
}

.black_text {
	color: black;
}

ul.status_tabs li a span.status_task_count_text {
	font-weight: bold;
	color: #3a3a3a;
}

legend.messages_legend {
	margin-bottom: -2%;
	font-size: 15px;
}

/*=============================BUTTON STYLES=============================*/
.custom_button {
  /*
  background-color: #80b3ff;
  border: 1px solid #4d94ff;
  */
}

.custom_button_icon {
	color: #ffffff;
}

.btn-circle {
	width: 30px;
	height: 30px;
	text-align: center;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.428571429;
	border-radius: 15px;
}

.btn-circle.btn-lg {
	width: 50px;
	height: 50px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 25px;
}
.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
  	font-size: 24px;
	line-height: 1.33;
	border-radius: 35px;
}

.view-close-btn {
	padding-bottom: 5px;
}
	.view-close-btn span {
		font-size: 18px;
	}

/*=============================TASK EDIT=============================*/
.task_edit_element {
	padding-bottom: 8px;
}

.input-group-coloured {
	background-color: #d0effb;
}

.special_case_input_addon {
	border-left: 1px solid #cccccc !important;
	border-right: 1px solid #cccccc !important;
}

/*=============================TASK VIEW=============================*/
.subtle_heading {
	font-weight: bold;
}

.subtle_heading_container {
	border-bottom: 1px solid #D9D9D9;
}

.attachment_legend {
	font-size: 11pt;
}

.custom_panel_header {
	background-color: #e8f7fd !important;
	border: 1px solid #d0effb !important;
}

.test_class {
	color: #d9d9d9 !important;
}

/*=============================PAGE LOADER=============================*/
/*
PLATSOFT COLOURS
DARK GREY - #424143
MID GREY - #BCBEC0
LIGHT GREY - #DCDDDE
HIGH BLUE - #00ABE4
LOWER BLUE - #6CCEF1
*/

.loading {
	height: 10;
	background-color: rgba(66,65,67,0.5);
	position: 1051;
	z-index: 1051;
	/*
	margin-top: 0px;
	*/
	top: 0px;
	left: 0px;
}

.modal-loader {
	background-color: rgba(66,65,67,0.5);
	opacity: .5;
	position: absolute;
	z-index: 1051;
	/*
	margin-top: 0px;
	*/
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
	.modal-loader > .loading-center{
		width: 100%;
		height: 100%;
	}

.loading-center {
	width: 100%;
	height: 100%;
	position: relative;
}

.loading-center-absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 200px;
	width: 200px;
	margin-top: -100px;
	margin-left: -100px;
	-ms-transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.object{
	-moz-border-radius: 50% 50% 50% 50%;
	-webkit-border-radius: 50% 50% 50% 50%;
	border-radius: 50% 50% 50% 50%;
	position: absolute;
	border-top: 7px solid #FFF;
	border-bottom: 7px solid transparent;
	border-left: 7px solid #FFF;
	border-right: 7px solid transparent;
	-webkit-animation: animate 2s infinite;
	animation: animate 2s infinite;
}

#object_one {
	left: 75px;
	top: 75px;
	width: 130px;
	height: 130px;
	border-top: 7px solid #DCDDDE;
	border-left: 7px solid #DCDDDE;
}

#object_two {
	left: 65px;
	top: 65px;
	width: 150px;
	height: 150px;
	border-top: 7px solid #6CCEF1;
	border-left: 7px solid #6CCEF1;
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

#object_three {
	left: 55px;
	top: 55px;
	width: 170px;
	height: 170px;
	border-top: 7px solid #DCDDDE;
	border-left: 7px solid #DCDDDE;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

#object_four {
	left: 45px;
	top: 45px;
	width: 190px;
	height: 190px;
	border-top: 7px solid #6CCEF1;
	border-left: 7px solid #6CCEF1;
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

@-webkit-keyframes animate {
	50% {
		-ms-transform: rotate(360deg) scale(1.1);
		-webkit-transform: rotate(360deg) scale(1.1);
		transform: rotate(360deg) scale(1.1);
	}
}

@keyframes animate {
	50% {
		-ms-transform: rotate(360deg) scale(1.1);
		-webkit-transform: rotate(360deg) scale(1.1);
		transform: rotate(360deg) scale(1.1);
	}
}

.ux_page > .ux_page_container {
	margin-bottom: 0px;
}

.ux_page_header {
	background-color: #428BCA;
}

.ux_page_help {
	font-size: 10pt;
}
	.ux_page_help:hover {
		color: #d9d9d9;
	}

.ux_header_text_container {
	font-size: 14pt;
	text-align: center;
	padding-top: 0.7%;
	margin-bottom: 1%;
	min-height: 3em;
}

.task_dropdown {
	float: left;
}

.task_heading {
	position: fixed;
}

.test {
	float: right;
}

.ux_header_text {
	font-size: 14pt;
	color: #ffffff;
	font-weight: bold;
}

.navbar {
	/*NEED TO OVERWRITE GRAPE-UI*/
	margin-bottom: -7px !important;
}

/* STATUS BAR */
.ux_status_list_container {
	margin-top: 1%;
	margin-bottom: 1%;
}

.ux_status_bar {
	border-bottom: 1px solid #D9D9D9;
	margin-top: -1.2%;
}

/* FILTER BAR */
.ux_filter_bar {
	background-color: #f2f2f2;
	border-bottom: 1px solid #d9d9d9;
}

.ux_filter_element_container {
	margin-top: 0.5%;
	margin-bottom: 0.5%;
}

.ux_filter_element {}

/* FILTER CRITERIA BAR */
.ux_filter_criteria_bar {
	border-bottom: 1px solid #d9d9d9;
	border-top: 1px solid #d9d9d9;
	background-color: #f2f2f2;
	padding-top: 0.5%;
	padding-bottom: 0.5%;
}

.ux_filter_heading {
	text-align: left;
	vertical-align: middle;
	color: #000000;
}

.ux_left_null_div {
	padding-left: 0%;
}

.ux_filter_criteria_element {
	padding-right: 0.5%;
}

.ux_result_counter {
	cursor: default;
}
	.ux_result_counter:hover {
		background-color: #ffffff;
		border: 1px solid #D9D9D9;
	}

.ux_filter_count {
	padding-top: 0%;
	padding-bottom: 0%;
	padding-right: 0.3%;
	padding-left: 0.3%
}

.ux_filter_selected {
	font-size: 12pt;
}

.ux_filter_button_group {
	padding-right: 0.4%;
}

.ux_filter_dropdown_selector_list {
	border-bottom: 1px solid #D9D9D9;
	text-align: center;
	padding-bottom: 6px
}

.ux_filter_dropdown_selector_text {
	cursor: pointer;
}
	.ux_filter_dropdown_selector_text:hover {
		text-decoration: underline;
		color: #428BCA;
	}

/* TASK ENTRIES */
.ux_task_row_container {
	border-bottom: 1px solid #D9D9D9;
}
	.ux_task_row_container:hover {
		border-bottom: 1px solid #D9D9D9;
		background-color: #f2f2f2;
	}

.ux_task_row_container_selected {
	background-color: #0086d5;
}
	.ux_task_row_container_selected:hover {
		border-bottom: 1px solid #D9D9D9;
		background-color: #6e00d5;
	}

.ux_task_row_complete {
	margin-top: 0.4%;
	margin-bottom: 0.2%;
}

.ux_priority_row_bar {
	height: 3px;
}

.ux_task_priority_indicator {
	text-align: center;
}

span.ux_priority_icon {
	margin-top: 8%;
	font-size: 12pt;
}

.ux_task_info {
	cursor: pointer;
	margin-bottom: 0.1%;
}

.ux_task_description_text {
	font-weight: bold;
}

.ux_task_priority_box {
	text-align: center;
}

.ux_context_menu_item {
	font-size: 13pt;
	cursor: pointer;
}
	.ux_context_menu_item:hover {
		color: #3385ff;
	}

.ux_active_context_menu_item {
	font-size: 13pt;
	cursor: pointer;
}
	.ux_active_context_menu_item:hover {
		color: #3385ff;
	}

.ux_inactive_context_menu_item_container {
	padding-right: 1%
}

.ux_hide_chevron {
	display: none;
}

.ux_context_menu_icon {
	padding-right: 2%;
	margin-top: 5px;
	font-size: 1.2em;
}

.ux_context_dropdown {
	position: relative;
	display: inline-block;
	float: right;
}

.ux_context_dropdown_content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 120px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	right: 130%;
	cursor: pointer;
}

.ux_context_dropdown_content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}
	.ux_context_dropdown_content a:hover {
		background-color: #f1f1f1;
	}

.show {
	display:block;
}

.ux_row_options {
	padding-right: 1.5%;
}

/* PAGING FOOTER */
.ux_paging_bar {
	background-color: #f2f2f2;
	border-bottom: 1px solid #D9D9D9;
}

.ux_paging_detail_container {
	margin-top: 0.5%;
	margin-bottom: 0.7%;
}

.ux_paging_result_count {
	margin-right: 2%;
}

.ux_paging_row_limiter {
	width: 50px;
	display: inline;
}

.ux_paging_row_limiter_text {
	display: inline;
}

.ux_paging_nav_buttons {
	padding-right: 0.7%;
}

/* REQUEST */
.ux_input_required {
	color: #D9D9D9;
	font-size: 8pt;
}

/* UX MODALS */
.modal-header {
	background-color: #428BCA;
}

.modal-title {
	color: #ffff;
	font-weight: bold;
	text-align: center;
}
	.modal-title a:hover span {
		color: #23527c;
	}

/* UX MESSAGES */
.ux_message_sender {
	font-weight: bold;
	cursor: pointer
}

.ux_message_list_panel {
	max-height: 350px;
	overflow-y: scroll;
}

.ux_message_date {
	font-size: 11px;
	float:right
}

/* UX ATTACHMENTS */
.ux_attachment_heading {
	margin-bottom: -2%;
	font-size: 15px;
}

.ux_attachment_uploader {
	font-weight: bold;
}
	.ux_attachment_uploader:hover {
		text-decoration: none;
	}

.ux_attachment_date {
	font-size: 11px;
	float:right
}

.ux_attachment_action_icon {
	padding-right: 3px;
	padding-left: 3px;
	cursor: pointer;
}
	.ux_attachment_action_icon:hover {
		color: #3385ff;
	}

.ux_attachment_list_panel {
	max-height: 350px;
	overflow-y: scroll;
}

/* TASK FLOW */
.ux_flow_list_label {
	float: left;
}

.ux_flow_ul_list {
	float: left;
	margin: 0;
	padding-left: 20px
}

/* FILTERS */
.ux_filter_dropdown_title {
	font-size: 10pt;
}

.ux_filter_dropdown_heading {
	cursor: pointer;
}

.ux_filter_dropdown {
	max-height: 350px;
	overflow-y: scroll
}

/* GENERAL */
.ux_general_link {
	cursor: pointer;
}

/*
	Kanban / Flex style updates. Need to confirm with rest of system for compatibility
 */
#grape_wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

#container {
	flex: 1;
	padding: 0px;
	-webkit-height: 80%;
}

#grape-wrapper #container {
	flex: 1;

	min-width: min-content;
	-moz-min-width: min-content;
	-webkit-min-width: min-content;

	min-height: min-content;
	-moz-min-height: min-content;
	-webkit-min-height: min-content;
}

#menu {
	flex: 0 0 52px;
	position: relative;
}

#page_footer {
	flex: 0 0 89px;
	position: relative;
	margin-top: -20px;
	z-index: -1;
}

.fullHeight {
	height: 100%;
}

.fullWidth {
	width: 100%;
}

.flex-container {
	display: flex;
	overflow: auto;
}

.flex-flipped {
	flex-direction: column;
}

.no-overflow {
	overflow: hidden;
}

.grape-ui {
	text-align: center;
}
.grape-ui > .form-container{
	text-align: left;
	width: 1280px;
	display: inline-block;
}

.hover .btn-group  {
	visibility:hidden;
}
	.hover:hover .btn-group {
		visibility: unset;
	}

select:invalid,
textarea:invalid,
input:invalid {
	border: 1px solid red;
}

.clickable {
	cursor: pointer;
}
	.clickable:hover {
		font-weight: bold;
		text-decoration:underline;
		text-decoration-style: dotted;
	}

a.fixed-color,
a.fixed-color:visited,
a.fixed-color:hover,
a.fixed-color:active {
	color: inherit;
}

.hours_description{
	font-size: 10px;
	color:#666;
}

span.label.tag {
	color: gray;
	font-weight: 700;
	border: lightgray solid 1px;
	padding: 0.1em;
	background-color: #f3f3f3;
	font-size: 0.9em;
}

#view_hours{
	overflow:scroll;	
	max-height: 350px;
}